#home-section {
  height: 100vh;
  width: 100%;
  color: #fff;
  /* padding-top: 5rem; */
  border-bottom: 10px solid #fff;
}
.home-content {
  width: 100%;
  height: 100%;
}
.home-bg {
  width: 100%;
  height: 100%;
}
.home-subcontent {
  justify-content: center;
  display: grid;
  position: relative;
  bottom: calc(50% + 400px);
  padding: 0 20px;
}
.home-subcontent .title-text {
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 0.2;
}
.home-subcontent .title-text-c {
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #9eff00;
  line-height: 0.2;
}
.home-subcontent .description-text {
  font-size: 38px;
  font-weight: 300;
  text-align: center;
  line-height: 1.5;
  color: #98989a;
  padding: 0 10%;
}
.home-bg .mountain-art-container {
  width: 100%;
  position: absolute;
  /* padding-top: 5rem; */
  bottom: 0%;
}
.home-bg .mountain-art {
  width: 100%;
  height: 600px;
}

/* Responsive Styles */
@media (max-width: 480px) {
  .home-bg .mountain-art {
    width: 100%;
    height: 250px;
  }
  .home-subcontent {
    bottom: calc(50% + 250px);
    padding: 0 10px;
  }
  .home-subcontent .title-text {
    font-size: 20px;
    line-height: 1.1;
  }
  .home-subcontent .title-text-c {
    font-size: 20px;
    /* line-height: 0.2; */
  }
  .home-subcontent .description-text {
    font-size: 16px;
    line-height: 1.1;
    padding: 0 5%;
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 481px) and (max-width: 768px) {
  .home-bg .mountain-art {
    width: 100%;
    height: 300px;
  }
  .home-subcontent {
    bottom: calc(50% + 250px);
    padding: 0 10px;
  }
  .home-subcontent .title-text {
    font-size: 24px;
    line-height: 0.2;
  }
  .home-subcontent .title-text-c {
    font-size: 24px;
    line-height: 0.2;
  }
  .home-subcontent .description-text {
    font-size: 20px;
    line-height: 1.1;
    padding: 0 5%;
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .home-bg .mountain-art {
    width: 100%;
    height: 350px;
  }
  .home-subcontent {
    bottom: calc(50% + 250px);
    padding: 0 10px;
  }
  .home-subcontent .title-text {
    font-size: 28px;
    line-height: 0.2;
  }
  .home-subcontent .title-text-c {
    font-size: 28px;
    line-height: 0.2;
  }
  .home-subcontent .description-text {
    font-size: 24px;
    line-height: 1.1;
    padding: 0 5%;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .home-bg .mountain-art {
    width: 100%;
    height: 400px;
  }
  .home-subcontent {
    bottom: calc(50% + 250px);
    padding: 0 10px;
  }
  .home-subcontent .title-text {
    font-size: 32px;
    line-height: 0.2;
  }
  .home-subcontent .title-text-c {
    font-size: 32px;
    line-height: 0.2;
  }
  .home-subcontent .description-text {
    font-size: 28px;
    line-height: 1.1;
    padding: 0 5%;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1600px) {
  .home-bg .mountain-art {
    width: 100%;
    height: 500px;
  }
  .home-subcontent {
    bottom: calc(50% + 250px);
    padding: 0 10px;
  }
  .home-subcontent .title-text {
    font-size: 38px;
    line-height: 0.2;
  }
  .home-subcontent .title-text-c {
    font-size: 38px;
    line-height: 0.2;
  }
  .home-subcontent .description-text {
    font-size: 34px;
    line-height: 1.1;
    padding: 0 5%;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1601px) {
  .home-bg .mountain-art {
    width: 100%;
    height: 600px;
  }
  .home-subcontent {
    bottom: calc(55% + 250px);
    padding: 0 10px;
  }
  .home-subcontent .title-text {
    font-size: 44px;
    line-height: 0.2;
  }
  .home-subcontent .title-text-c {
    font-size: 44px;
    line-height: 0.2;
  }
  .home-subcontent .description-text {
    font-size: 38px;
    line-height: 1.1;
    padding: 0 5%;
  }
}
