/* Base Styles */
.navbar {
  display: flex;
  width: 100%;
  position: relative;
  top: 0;
  z-index: 1000;
  align-items: center;
  justify-content: center;
}

.navbar .navbar-content {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid white;
  padding: 14px;
}
.navbar .logo {
  display: flex;
  align-items: center;
}
.navbar .img {
  height: 75px;
  width: 200px;
}
.navbar .menu {
  display: none;
}

.navbar .menu.show {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  position: absolute;
  top: 100%;
  /* left: 0; */
  width: 100%;
  background-color: #1a1a1a; /* Background color */
  text-align: center;
  /* padding: 20px 0; */
}

.navbar .menu-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar .menu-toggle svg {
  width: 40px;
  height: 40px;
  color: #ffffff;
}

.navbar .menu-items {
  display: flex;
  gap: 200px;
  color: white;
}

.navbar .menu-items li {
  list-style: none;
}

.navbar .menu-items li a {
  text-decoration: none;
  color: inherit;
}

.navbar .contact-button {
  background-color: #9eff00;
  color: black;
  font-weight: bold;
  font-size: 22px;
  width: 180px;
  height: 60px;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 480px) {
  .navbar .img {
    height: 50px;
    width: 120px;
  }
  .navbar .menu-items {
    display: none; /* Completely hide menu items on small screens */
  }
  .navbar .contact-button {
    display: none; /* Completely hide contact button on small screens */
  }
  .navbar .menu {
    display: block;
  }
  .navbar .menu.show .menu-items {
    display: block;
    background-color: #1a1a1a;
    text-align: center;
  }
  .navbar .menu.show .menu-items li {
    padding: 10px 0;
  }
  .navbar .menu.show .menu-items li a {
    font-size: 18px;
  }
  .navbar .menu.show .contact-button {
    display: block;
    margin: 10px auto;
    font-size: 14px;
    width: 140px;
    height: 45px;
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 481px) and (max-width: 768px) {
  .navbar .img {
    height: 60px;
    width: 160px;
  }
  .navbar .menu-items {
    display: none; /* Completely hide menu items on small screens */
  }
  .navbar .contact-button {
    display: none;
  }
  .navbar .menu {
    display: block;
  }
  .navbar .menu.show .menu-items {
    display: block;
    width: 100%;
    background-color: #1a1a1a;
    text-align: center;
  }
  .navbar .menu.show .menu-items li {
    padding: 10px 0;
  }
  .navbar .menu.show .menu-items li a {
    font-size: 18px;
  }
  .navbar .menu.show .contact-button {
    display: block;
    margin: 10px auto;
    font-size: 14px;
    width: 140px;
    height: 45px;
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .navbar .img {
    height: 65px;
    width: 190px;
  }
  .navbar .menu-items {
    display: none; /* Completely hide menu items on small screens */
  }
  .navbar .contact-button {
    display: none; /* Completely hide contact button on small screens */
  }
  .navbar .menu {
    display: block;
  }
  .navbar .menu.show {
    display: block;
  }
  .navbar .menu.show .menu-items {
    display: block;
    /* position: absolute; */
    /* top: 100%; */
    /* left: 0; */
    width: 100%;
    background-color: #1a1a1a;
    text-align: center;
  }
  .navbar .menu.show .menu-items li {
    padding: 10px 0;
  }
  .navbar .menu.show .menu-items li a {
    font-size: 18px;
  }
  .navbar .menu.show .contact-button {
    display: block;
    margin: 10px auto;
    font-size: 14px;
    width: 140px;
    height: 45px;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .navbar .img {
    height: 70px;
    width: 190px;
  }
  .navbar .menu-toggle {
    display: none;
  }
  .navbar .menu.show {
    display: none;
  }
  .navbar .menu-items {
    gap: 45px;
  }
  .navbar .contact-button {
    font-size: 16px;
    height: 50px;
    width: 150px;
    border-radius: 8px;
    padding: 12px 24px;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1600px) {
  .navbar .img {
    height: 75px;
    width: 200px;
  }
  .navbar .menu-toggle {
    display: none;
  }
  .navbar .menu-items {
    gap: 60px;
  }
  .navbar .contact-button {
    font-size: 18px;
    height: 55px;
    width: 160px;
    border-radius: 8px;
    padding: 12px 24px;
  }
  .navbar .menu.show {
    display: none;
  }
}
/* Extra Large Devices (Large Desktops) */
@media (min-width: 1601px) {
  .navbar .img {
    height: 75px;
    width: 200px;
  }
  .navbar .menu-toggle {
    display: none;
  }
  .navbar .menu.show {
    display: none;
  }
  .navbar .contact-button {
    font-size: 18px;
    height: 60px;
    width: 180px;
    border-radius: 8px;
    padding: 12px 24px;
  }
}
