.background-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.background-container .background-img {
  height: 160px;
  width: 600px;
}

/* Responsive Styles */
@media (max-width: 480px) {
  .background-container .background-img {
    height: 55px;
    width: 230px;
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 481px) and (max-width: 768px) {
  .background-container .background-img {
    height: 65px;
    width: 250px;
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .background-container .background-img {
    height: 80px;
    width: 300px;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .background-container .background-img {
    height: 90px;
    width: 350px;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1600px) {
  .background-container .background-img {
    height: 100px;
    width: 400px;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1601px) {
  .background-container .background-img {
    height: 160px;
    width: 600px;
  }
}
