/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

body {
  font-family: Arial, sans-serif;
  background-color: #1a1a1a;
}

.container {
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  margin-top: 4rem;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input,
textarea {
  width: 95%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #7ecc00;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #9eff00;
}

.checklist {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.checklist label {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.checklist input {
  width: 40px;
  margin-right: 0.5rem;
}

/* Footer Styles */
.footer {
  text-align: center;
  padding: 1rem;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  min-height: 100vh;
}

.footer p {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure the loader is above other elements */
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.breadcrum-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-content: space-between;
}
.breadcrum {
  /* width: 50%; */
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  /* background-color: aqua; */
}

.link-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  color: white;
}
.link-description {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  color: #9eff00;
}

.logo {
  display: flex;
  align-items: center;
}
.img {
  height: 75px;
  width: 200px;
}

.icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}
.icon-container .social-icon {
  height: 20px;
  width: 20px;
}

/* Responsive Styles */
@media (max-width: 600px) {
  form {
    padding: 1rem;
  }
  .footer {
    padding: 1rem;
  }
}
