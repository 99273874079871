/* Base Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #9eff00;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9eff00;
}

/* Responsive Styles */

/* Small Devices (Phones) */
@media (max-width: 480px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #7ecc00;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9eff00;
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 481px) and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #7ecc00;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9eff00;
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #7ecc00;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9eff00;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #7ecc00;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9eff00;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #7ecc00;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9eff00;
  }
}
