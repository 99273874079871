.card-container {
  height: 370px;
  width: 370px;
  background-color: white;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-radius: 8px;
  transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
}

.card-container.pressed {
  transform: scale(0.98);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0.7; /* Change opacity to simulate pressed effect */
}

.card-content {
  /* padding: 10px; */
  padding-left: 20px;
  padding-right: 20px;
}

.card-container .card-icon-container {
}
.card-container .card-icon-container .card-icon {
  height: 74px;
  width: 74px;
}

.card-container .title-container {
}
.card-container .title-container .title-text {
  color: black;
  cursor: pointer;
  font-size: 20px; /* Equivalent to text-lg */
  line-height: 0;
  font-family: "Barlow", sans-serif; /* Make sure you have the Barlow font loaded in your project */
  /* padding: 0.1rem 0; Add some padding for better clickability */
  display: inline-block; /* Ensure the hover effect covers the text */
  text-align: left;
}

.card-container .description-container {
}
.card-container .description-container .description-text {
  color: grey;
  cursor: pointer;
  font-size: 15px; /* Equivalent to text-lg */
  line-height: 1.1;
  font-family: "Barlow", sans-serif; /* Make sure you have the Barlow font loaded in your project */
  /* padding: 0.1rem 0; Add some padding for better clickability */
  display: inline-block; /* Ensure the hover effect covers the text */
  text-align: left;
}

/* Responsive Styles */
@media (max-width: 480px) {
  .card-container {
    height: 150px;
    width: 150px;
  }
  .card-container .card-icon-container .card-icon {
    height: 50px;
    width: 50px;
  }
  .card-container .title-container .title-text {
    font-size: 10px;
    line-height: 1.1;
  }
  .card-container .description-container .description-text {
    font-size: 6px; /* Equivalent to text-lg */
    line-height: 1.1;
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 481px) and (max-width: 768px) {
  .card-container {
    height: 210px;
    width: 210px;
  }
  .card-container .card-icon-container .card-icon {
    height: 60px;
    width: 60px;
  }
  .card-container .title-container .title-text {
    font-size: 12px;
    line-height: 1.1;
  }
  .card-container .description-container .description-text {
    font-size: 8px; /* Equivalent to text-lg */
    line-height: 1.1;
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .card-container {
    height: 250px;
    width: 250px;
  }
  .card-container .card-icon-container .card-icon {
    height: 65px;
    width: 65px;
  }
  .card-container .title-container .title-text {
    font-size: 16px;
    line-height: 1.1;
  }
  .card-container .description-container .description-text {
    font-size: 10px; /* Equivalent to text-lg */
    line-height: 1.1;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .card-container {
    height: 315px;
    width: 315px;
  }
  .card-container .card-icon-container .card-icon {
    height: 72px;
    width: 72px;
  }
  .card-container .title-container .title-text {
    font-size: 18px;
    line-height: 1.1;
  }
  .card-container .description-container .description-text {
    font-size: 13px; /* Equivalent to text-lg */
    line-height: 1.1;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1600px) {
  .card-container {
    height: 370px;
    width: 370px;
  }
  .card-container .card-icon-container .card-icon {
    height: 74px;
    width: 74px;
  }
  .card-container .title-container .title-text {
    font-size: 20px;
    line-height: 1.1;
  }
  .card-container .description-container .description-text {
    font-size: 15px; /* Equivalent to text-lg */
    line-height: 1.1;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1601px) {
  .card-container {
    height: 340px;
    width: 340px;
  }
  .card-container .card-icon-container .card-icon {
    height: 75px;
    width: 75px;
  }
  .card-container .title-container .title-text {
    font-size: 20px;
    line-height: 1.1;
  }
  .card-container .description-container .description-text {
    font-size: 15px; /* Equivalent to text-lg */
    line-height: 1.1;
  }
}
