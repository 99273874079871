.footer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.footer-container .section-one,
.footer-container .section-two,
.footer-container .section-three,
.footer-container .section-four {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1; /* Added to allow sections to grow/shrink evenly */
  padding: 20px; /* Optional: Adjust padding to add space between sections */
}

.footer-container .section-one .app-icon {
  height: 60px;
  width: 155px;
}

.footer-container .section-one .description,
.footer-container .title,
.footer-container .content {
  color: #ffffff;
}

.footer-container .section-one .description {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 1.8;
}

.footer-container .title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  line-height: 1.1;
}

.footer-container .content {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 1.1;
}

.footer-container .icon-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  /* justify-content: space-between; */
}
.footer-container .icon-container .social-icon {
  height: 20px;
  width: 20px;
}

@media (max-width: 479px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .footer-container .section-one,
  .footer-container .section-two,
  .footer-container .section-three,
  .footer-container .section-four {
    padding: 10px;
  }

  .footer-container .section-one .app-icon {
    height: 40px;
    width: 100px;
  }

  .footer-container .section-one .description {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    line-height: 1.8;
  }

  .footer-container .title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .content {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .icon-container {
    justify-content: center;
  }
}
/* Small Devices (Phones) */
@media (min-width: 480px) and (max-width: 649px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .footer-container .section-one,
  .footer-container .section-two,
  .footer-container .section-three,
  .footer-container .section-four {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1; /* Added to allow sections to grow/shrink evenly */
    padding: 5px; /* Optional: Adjust padding to add space between sections */
  }

  .footer-container .section-one .app-icon {
    height: 40px;
    width: 100px;
  }

  .footer-container .section-one .description,
  .footer-container .title,
  .footer-container .content {
    color: #ffffff;
  }

  .footer-container .section-one .description {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    line-height: 1.8;
  }

  .footer-container .title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .content {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .icon-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* justify-content: space-between; */
  }
  .footer-container .icon-container .social-icon {
    height: 15px;
    width: 15px;
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 650px) and (max-width: 768px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .footer-container .section-one,
  .footer-container .section-two,
  .footer-container .section-three,
  .footer-container .section-four {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1; /* Added to allow sections to grow/shrink evenly */
    padding: 10px; /* Optional: Adjust padding to add space between sections */
  }

  .footer-container .section-one .app-icon {
    height: 60px;
    width: 155px;
  }

  .footer-container .section-one .description,
  .footer-container .title,
  .footer-container .content {
    color: #ffffff;
  }

  .footer-container .section-one .description {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 1.8;
  }

  .footer-container .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .content {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .icon-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    /* justify-content: space-between; */
  }
  .footer-container .icon-container .social-icon {
    height: 20px;
    width: 20px;
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .footer-container .section-one,
  .footer-container .section-two,
  .footer-container .section-three,
  .footer-container .section-four {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1; /* Added to allow sections to grow/shrink evenly */
    padding: 10px; /* Optional: Adjust padding to add space between sections */
  }

  .footer-container .section-one .app-icon {
    height: 60px;
    width: 155px;
  }

  .footer-container .section-one .description,
  .footer-container .title,
  .footer-container .content {
    color: #ffffff;
  }

  .footer-container .section-one .description {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 1.8;
  }

  .footer-container .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .content {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .icon-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    /* justify-content: space-between; */
  }
  .footer-container .icon-container .social-icon {
    height: 20px;
    width: 20px;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .footer-container .section-one,
  .footer-container .section-two,
  .footer-container .section-three,
  .footer-container .section-four {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1; /* Added to allow sections to grow/shrink evenly */
    padding: 10px; /* Optional: Adjust padding to add space between sections */
  }

  .footer-container .section-one .app-icon {
    height: 60px;
    width: 155px;
  }

  .footer-container .section-one .description,
  .footer-container .title,
  .footer-container .content {
    color: #ffffff;
  }

  .footer-container .section-one .description {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 1.8;
  }

  .footer-container .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .content {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .icon-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    /* justify-content: space-between; */
  }
  .footer-container .icon-container .social-icon {
    height: 20px;
    width: 20px;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1600px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .footer-container .section-one,
  .footer-container .section-two,
  .footer-container .section-three,
  .footer-container .section-four {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1; /* Added to allow sections to grow/shrink evenly */
    padding: 20px; /* Optional: Adjust padding to add space between sections */
  }

  .footer-container .section-one .app-icon {
    height: 60px;
    width: 155px;
  }

  .footer-container .section-one .description,
  .footer-container .title,
  .footer-container .content {
    color: #ffffff;
  }

  .footer-container .section-one .description {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 1.8;
  }

  .footer-container .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .content {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .icon-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    /* justify-content: space-between; */
  }
  .footer-container .icon-container .social-icon {
    height: 20px;
    width: 20px;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1601px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .footer-container .section-one,
  .footer-container .section-two,
  .footer-container .section-three,
  .footer-container .section-four {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1; /* Added to allow sections to grow/shrink evenly */
    padding: 20px; /* Optional: Adjust padding to add space between sections */
  }

  .footer-container .section-one .app-icon {
    height: 60px;
    width: 155px;
  }

  .footer-container .section-one .description,
  .footer-container .title,
  .footer-container .content {
    color: #ffffff;
  }

  .footer-container .section-one .description {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 1.8;
  }

  .footer-container .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .content {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 1.1;
  }

  .footer-container .icon-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    /* justify-content: space-between; */
  }
  .footer-container .icon-container .social-icon {
    height: 20px;
    width: 20px;
  }
}
