#service-section {
  height: 100vh;
  color: #fff;
  padding-top: 5rem;
  border-bottom: 10px solid #fff;
}

.service-content {
  width: 100%;
  height: 100%;
}

.service-bg {
  width: 100%;
  height: 100%;
}

.service-subcontent {
  justify-content: center;
  display: grid;
  position: relative;
  bottom: calc(50% + 400px);
  padding: 0 10px;
}

.service-subcontent .title-text {
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 0.2;
}
.service-subcontent .description-text {
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #9eff00;
  line-height: 0.2;
}

.service-content .card-grid-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: center;
}

/* Responsive Styles */
@media (max-width: 480px) {
  .service-subcontent {
    bottom: calc(50% + 400px);
    padding: 0 10px;
  }
  .service-subcontent .title-text {
    font-size: 20px;
    line-height: 1.1;
  }
  .service-subcontent .description-text {
    font-size: 20px;
    /* line-height: 0.2; */
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 481px) and (max-width: 768px) {
  .service-subcontent {
    bottom: calc(50% + 490px);
    padding: 0 10px;
  }
  .service-subcontent .title-text {
    font-size: 24px;
    line-height: 1.1;
  }
  .service-subcontent .description-text {
    font-size: 24px;
    /* line-height: 0.2; */
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .service-subcontent {
    bottom: calc(50% + 480px);
    padding: 0 10px;
  }
  .service-subcontent .title-text {
    font-size: 28px;
    line-height: 0.2;
  }
  .service-subcontent .description-text {
    font-size: 28px;
    line-height: 0.2;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .service-subcontent {
    bottom: calc(50% + 480px);
    padding: 0 10px;
  }
  .service-subcontent .title-text {
    font-size: 32px;
    line-height: 0.2;
  }
  .service-subcontent .description-text {
    font-size: 32px;
    line-height: 0.2;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1600px) {
  .service-subcontent {
    bottom: calc(50% + 480px);
    padding: 0 10px;
  }
  .service-subcontent .title-text {
    font-size: 38px;
    line-height: 0.2;
  }
  .service-subcontent .description-text {
    font-size: 38px;
    line-height: 0.2;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1601px) {
  .service-subcontent {
    bottom: calc(50% + 480px);
    padding: 0 10px;
  }
  .service-subcontent .title-text {
    font-size: 44px;
    line-height: 0.2;
  }
  .service-subcontent .description-text {
    font-size: 44px;
    line-height: 0.2;
  }
  .service-content .card-grid-container {
    padding-right: 20%;
    padding-left: 20%;
  }
}
