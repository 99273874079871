#about-section {
  height: 100vh;
  color: #fff;
  padding-top: 5rem;
  border-bottom: 10px solid #fff;
}

.about-content {
  width: 100%;
  height: 100%;
}

.about-bg {
  width: 100%;
  height: 100%;
}

.about-subcontent-c {
  position: relative;
  bottom: calc(50% + 550px);
  /* display: grid; */
  /* gap: 40px; */
  /* display: flex;
  flex-direction: row; */
}
.about-subcontent {
  /* position: relative;
  bottom: calc(50% + 550px);
  display: flex;
  flex-direction: row; */
  width: 100%;
  display: flex;
  flex-direction: row;
}
.about-subcontent .text-container {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.about-subcontent .decription-container {
  width: 50%;
  align-items: center;
}

.about-subcontent .title-text {
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 0.2;
}
.about-subcontent .title-text-c {
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #9eff00;
  line-height: 0.2;
}
.about-subcontent .description-text {
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  line-height: 1.5;
  color: white;
  padding: 0 10%;
}
.about-subcontent .star-icon-container {
}
.about-subcontent .star-icon {
  height: 100px;
  width: 100px;
}
.about-subcontent-c .meeting-icon-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.about-subcontent-c .meeting-icon {
  height: 528px;
  width: 1184px;
}

/* Responsive Styles */
@media (max-width: 480px) {
  .about-subcontent-c {
    bottom: calc(50% + 350px);
    padding: 0 10px;
  }
  .about-subcontent .title-text {
    font-size: 24px;
    line-height: 0.2;
  }
  .about-subcontent .title-text-c {
    font-size: 24px;
    line-height: 1.1;
  }
  .about-subcontent .description-text {
    font-size: 10px;
    line-height: 1.1;
    padding: 0 5%;
  }
  .about-subcontent .star-icon {
    height: 50px;
    width: 50px;
  }
  .about-subcontent-c .meeting-icon {
    height: 250px;
    width: 300px;
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 481px) and (max-width: 768px) {
  .about-subcontent-c {
    bottom: calc(50% + 420px);
    padding: 0 10px;
  }
  .about-subcontent .title-text {
    font-size: 32px;
    line-height: 0.2;
  }
  .about-subcontent .title-text-c {
    font-size: 32px;
    line-height: 1.1;
  }
  .about-subcontent .description-text {
    font-size: 16px;
    line-height: 1.1;
    padding: 0 5%;
  }
  .about-subcontent .star-icon {
    height: 60px;
    width: 60px;
  }
  .about-subcontent-c .meeting-icon {
    height: 350px;
    width: 475px;
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .about-subcontent-c {
    bottom: calc(50% + 450px);
    padding: 0 10px;
  }
  .about-subcontent .title-text {
    font-size: 36px;
    line-height: 0.2;
  }
  .about-subcontent .title-text-c {
    font-size: 36px;
    line-height: 0.2;
  }
  .about-subcontent .description-text {
    font-size: 18px;
    line-height: 1.1;
    padding: 0 5%;
  }
  .about-subcontent .star-icon {
    height: 75px;
    width: 75px;
  }
  .about-subcontent-c .meeting-icon {
    height: 430px;
    width: 765px;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .about-subcontent-c {
    bottom: calc(50% + 450px);
    padding: 0 10px;
  }
  .about-subcontent .title-text {
    font-size: 40px;
    line-height: 0.2;
  }
  .about-subcontent .title-text-c {
    font-size: 40px;
    line-height: 0.2;
  }
  .about-subcontent .description-text {
    font-size: 20px;
    line-height: 1.1;
    padding: 0 5%;
  }
  .about-subcontent .star-icon {
    height: 85px;
    width: 85px;
  }
  .about-subcontent-c .meeting-icon {
    height: 500px;
    width: 1000px;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1600px) {
  .about-subcontent-c {
    bottom: calc(50% + 450px);
    padding: 0 10px;
  }
  .about-subcontent .title-text {
    font-size: 44px;
    line-height: 0.2;
  }
  .about-subcontent .title-text-c {
    font-size: 44px;
    line-height: 0.2;
  }
  .about-subcontent .description-text {
    font-size: 24px;
    line-height: 1.5;
    padding: 0 5%;
  }
  .about-subcontent .star-icon {
    height: 100px;
    width: 100px;
  }
  .about-subcontent-c .meeting-icon {
    height: 528px;
    width: 1184px;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1601px) {
  .about-subcontent-c {
    bottom: calc(70% + 250px);
    padding: 0 10px;
  }
  .about-subcontent .title-text {
    font-size: 42px;
    line-height: 0.2;
  }
  .about-subcontent .title-text-c {
    font-size: 42px;
    line-height: 0.2;
  }
  .about-subcontent .description-text {
    font-size: 34px;
    line-height: 1.1;
    padding: 0 5%;
  }
  .about-subcontent .star-icon {
    height: 100px;
    width: 100px;
  }
  .about-subcontent-c .meeting-icon {
    height: 470px;
    width: 1100px;
  }
}
