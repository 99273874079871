.services-container {
  padding: 2rem;
  min-height: 100vh;
}

.header {
  width: 100%;
}

.breadcrum-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-content: space-between;
}
.breadcrum {
  /* width: 50%; */
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  /* background-color: aqua; */
}

.link-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  color: white;
}
.link-description {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  color: #9eff00;
}

.logo {
  display: flex;
  align-items: center;
}
.img {
  height: 75px;
  width: 200px;
}

.service-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}
.service-icon-container .service-social-icon {
  height: 20px;
  width: 20px;
}

.service-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  margin-top: 2rem;
}

.service-detail__header {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.service-detail__description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  /* text-align: justify; */
}

.service-detail__subheader {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.service-detail__list {
  list-style-type: disc;
  margin-left: 10px;
}

.services-footer-bottom {
  color: #fff;
  display: flex;
  justify-content: center;
}

/* Responsive Styles */
@media (max-width: 480px) {
  .img {
    height: 50px;
    width: 120px;
  }
  .service-icon-container {
    gap: 0.8rem;
  }
  .service-icon-container .service-social-icon {
    height: 15px;
    width: 15px;
  }
  .breadcrum {
    gap: 0.4rem;
  }
  .link-title {
    font-size: 10px;
  }
  .link-description {
    font-size: 10px;
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 481px) and (max-width: 768px) {
  .img {
    height: 80px;
    width: 200px;
  }
  .service-icon-container {
    gap: 1.2rem;
  }
  .service-icon-container .service-social-icon {
    height: 18px;
    width: 18px;
  }
  .breadcrum {
    gap: 0.8rem;
  }
  .link-title {
    font-size: 20px;
    line-height: 1.1;
  }
  .link-description {
    font-size: 20px;
  }
  .service-detail__header {
    font-size: 1.9rem;
  }

  .service-detail__description {
    font-size: 1.5rem;
  }

  .service-detail__subheader {
    font-size: 1.8rem;
  }

  .service-detail__list {
    font-size: x-large;
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .img {
    height: 90px;
    width: 220px;
  }
  .service-icon-container {
    gap: 1.4rem;
  }
  .service-icon-container .service-social-icon {
    height: 22px;
    width: 22px;
  }
  .breadcrum {
    gap: 0.8rem;
  }
  .link-title {
    font-size: 20px;
    line-height: 1.1;
  }
  .link-description {
    font-size: 18px;
  }
  .service-detail__header {
    font-size: 2.2rem;
  }

  .service-detail__description {
    font-size: 1.8rem;
  }

  .service-detail__subheader {
    font-size: 2rem;
  }

  .service-detail__list {
    font-size: x-large;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .img {
    height: 100px;
    width: 240px;
  }
  .service-icon-container {
    gap: 1.6rem;
  }
  .service-icon-container .service-social-icon {
    height: 25px;
    width: 25px;
  }
  .breadcrum {
    gap: 0.9rem;
  }
  .link-title {
    font-size: 22px;
    line-height: 1.1;
  }
  .link-description {
    font-size: 22px;
  }
  .service-detail__header {
    font-size: 2.5rem;
  }

  .service-detail__description {
    font-size: 1.9rem;
  }

  .service-detail__subheader {
    font-size: 2.4rem;
  }

  .service-detail__list {
    font-size: xx-large;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1600px) {
  .img {
    height: 120px;
    width: 280px;
  }
  .service-icon-container {
    gap: 1.9rem;
  }
  .service-icon-container .service-social-icon {
    height: 28px;
    width: 28px;
  }
  .breadcrum {
    gap: 1rem;
  }
  .link-title {
    font-size: 24px;
    line-height: 1.1;
  }
  .link-description {
    font-size: 24px;
  }
  .service-detail {
    max-width: 1200px;
    margin: 10 auto;
    padding: 30px;
  }
  .service-detail__header {
    font-size: 3.2rem;
  }

  .service-detail__description {
    font-size: 2.1rem;
  }

  .service-detail__subheader {
    font-size: 2.6rem;
  }

  .service-detail__list {
    font-size: xx-large;
  }
}
/* Extra Large Devices (Large Desktops) */
@media (min-width: 1601px) {
  .img {
    height: 140px;
    width: 360px;
  }
  .service-icon-container {
    gap: 2.2rem;
  }
  .service-icon-container .service-social-icon {
    height: 32px;
    width: 32px;
  }
  .breadcrum {
    gap: 1.4rem;
  }
  .link-title {
    font-size: 28px;
    line-height: 1.1;
  }
  .link-description {
    font-size: 28px;
  }
  .service-detail {
    max-width: 2000px;
    margin: 20 auto;
    padding: 30px;
  }
  .service-detail__header {
    font-size: 4.2rem;
  }

  .service-detail__description {
    font-size: 3rem;
  }

  .service-detail__subheader {
    font-size: 2.9rem;
  }

  .service-detail__list {
    font-size: xx-large;
  }
}
