/* Define the base styles for the link component */
.link-container {
  border-bottom: 2px solid transparent;
}

.link-container:hover {
  border-bottom: 2px solid white;
}

.link-title {
  /* background-color: white; */
  color: white;
  cursor: pointer;
  font-size: 30px; /* Equivalent to text-lg */
  line-height: 0;
  font-family: "Barlow", sans-serif; /* Make sure you have the Barlow font loaded in your project */
  /* padding: 0.1rem 0; Add some padding for better clickability */
  display: inline-block; /* Ensure the hover effect covers the text */
}

/* Responsive Styles */
@media (max-width: 480px) {
  .link-container {
    border-bottom: 0;
  }

  .link-container:hover {
    border-bottom: 0;
  }

  .link-title {
    /* background-color: white; */
    color: white;
    cursor: pointer;
    font-size: 18px; /* Equivalent to text-lg */
    line-height: 0.2;
    font-family: "Barlow", sans-serif; /* Make sure you have the Barlow font loaded in your project */
    /* padding: 0.1rem 0; Add some padding for better clickability */
    display: inline-block; /* Ensure the hover effect covers the text */
  }
}

/* Small Devices (Phablets and Large Phones) */
@media (min-width: 481px) and (max-width: 768px) {
  .link-container {
    border-bottom: 0;
  }

  .link-container:hover {
    border-bottom: 0;
  }

  .link-title {
    /* background-color: white; */
    color: white;
    cursor: pointer;
    font-size: 20px; /* Equivalent to text-lg */
    line-height: 0.2;
    font-family: "Barlow", sans-serif; /* Make sure you have the Barlow font loaded in your project */
    /* padding: 0.1rem 0; Add some padding for better clickability */
    display: inline-block; /* Ensure the hover effect covers the text */
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .link-title {
    font-size: 22px; /* Equivalent to text-lg */
    line-height: 0.2;
  }
}

/* Large Devices (Laptops and Desktops) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .link-title {
    font-size: 24px; /* Equivalent to text-lg */
    line-height: 0.2;
  }
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1600px) {
  .link-title {
    font-size: 26px; /* Equivalent to text-lg */
    line-height: 0.2;
  }
}
/* Extra Large Devices (Large Desktops) */
@media (min-width: 1601px) {
  .link-title {
    font-size: 28px; /* Equivalent to text-lg */
    line-height: 0.2;
  }
}
